<template>
    <div
        :style="$store.state.appConfig.layout.skin == 'dark' ? 'background-color: #0B0B0B' : 'background-color: #E5E7EB'"
    >
        <b-row>
            <b-col  class="overflow-hidden">
                <div class="w-100 d-flex align-items-center justify-content-between mb-2">
                    <div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <div 
                            @click.prevent="slidePrev" 
                            :class="$store.state.appConfig.layout.skin === 'dark' ? 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-custom-dark p-1 cursor-pointer text-primary' : 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-white p-1 cursor-pointer text-primary'">
                            <i class="fa-solid fa-arrow-left"></i>
                        </div>
                        <div 
                            @click.prevent="slideNext" 
                            :class="$store.state.appConfig.layout.skin === 'dark' ? 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-custom-dark p-1 cursor-pointer text-primary' : 'd-flex justify-content-center align-items-center mr-2 rounded-circle bg-white p-1 cursor-pointer text-primary'"
                        >
                            <i class="fa-solid fa-arrow-right"></i>
                        </div>
                    </div>
                </div>
                <b-row v-if="load">
                    <b-col md="12" class="d-flex justify-content-center" >
                        <b-spinner variant="blue" label="Spinning"></b-spinner>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col v-if="items" ref="hooperWrapper">
                        <hooper 
                            :settings="hooperSettings"  
                            :infiniteScroll="false"
                            ref="carousel" 
                            class="mb-1 w-100 hooper1"
                            @slide="updateCarousel"
                        >
                            <slide 
                                class="slide cursor-pointer py-1" 
                                v-for="(item, index) in items" 
                                :key="item.id" 
                                style="max-height: 290px"
                            >
                                <div @click="makeSlideActive(index, item)" >
                                    <div :class="$store.state.appConfig.layout.skin == 'dark' ? 'dark-slide-container px-2 py-1 mx-2 shadow' : 'slide-container px-2 py-1 mx-2 shadow' ">
                                        <b-row>
                                            <b-col md="12" class="d-flex align-items-center">
                                                <div class="percentage-container">
                                                    <div 
                                                        class="inner-percentage"
                                                        :style="{ borderColor: getRandomColor(index) }"
                                                    >
                                                        <div class="percentage">
                                                            <h3 class="m-0 ml-25 text-light">{{item.curriculum}}%</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <span class="ml-1 w-75">
                                                    <h5 class="pr-1">{{ item.name }}</h5>
                                                    <!-- <b-progress
                                                        height="2rem"
                                                        width="100%"
                                                        :value="item.curriculum"
                                                        max="100"
                                                        :variant="getRandomColor(index)"
                                                    /> -->
                                                    <b-progress
                                                        height="2rem"
                                                        width="100%"
                                                        :value="'70'"
                                                        max="100"
                                                    >
                                                    <b-progress-bar
                                                        :value="'70'"
                                                        :style="{ backgroundColor: getRandomColor(index) }"
                                                    />
                                                    </b-progress>
                                                </span>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </div>
                            </slide>
                        </hooper>
                    </b-col>
                </b-row>
                <!-- <center v-if="topicsLoad">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                </center> -->
                <!-- <b-row v-else> -->
                <b-row >
                    <b-col md="12">
                        <b-card style="border-radius: 20px" :class="$store.state.appConfig.layout.skin == 'dark' ? 'dark-card' :''">
                            <b-row class="d-flex justify-content-between">
                                <h4 :class="$store.state.appConfig.layout.skin == 'dark' ? 'text-white ml-1':'ml-1'" v-if="currentModule">{{ currentModule.name }}</h4>
                                
                            </b-row>
                            <hr class="w-100" />
                            <b-tabs :class="$store.state.appConfig.layout.skin == 'dark' ? 'w-100 rounded-0 cutom-tabs-dark' : 'w-100 rounded-0'" content-class="mt-2 w-100 px-2" lazy>
                                <b-tab  title="Work Completed" active>
                                    <!-- <p v-if="student && authUser().user.type == 2">Here is an overview of how you are doing in the different curriculums, by selecting a curriculum you can have deeper insights into the sub-topics associated with the curriculum.</p> -->
                                    <WorkCompleted/>
                                </b-tab>
                                <b-tab title="Certificate">
                                    <!-- dddddsaa -->
                                    <Certificate/>
                                </b-tab>
                            </b-tabs>
                            
                            
                        </b-card>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import { mapGetters } from "vuex";
import VueApexCharts from 'vue-apexcharts';
import {
  BCard, 
  BRow, 
  BCol, 
  BCardText, 
  BButton,
  BLink, 
  BListGroup, 
  BListGroupItem, 
  BCardTitle, 
  BCardBody,
  BTab, BTabs,
  BSpinner,
  BBadge,
  BCardImg,
  BProgress,
  BProgressBar,
  VBTooltip,
  BAlert
} from 'bootstrap-vue'
import WorkCompleted from '@/views/modules/students/WorkCompleted.vue'
import Certificate from '@/views/modules/students/Certificate.vue'

import { filter } from 'postcss-rtl/lib/affected-props';
  export default {
    name: 'RecommendedLearning',
    components: {
        BCard, 
        BRow, 
        BCol, 
        BCardText, 
        BButton,
        BLink, 
        BListGroup, 
        BListGroupItem, 
        BCardTitle, 
        BCardBody,
        BTab, BTabs,
        BSpinner,
        BAlert,
        BBadge,
        Hooper,
        Slide,
        BProgressBar,
        BProgress,
        BCardImg,
        VueApexCharts,
        WorkCompleted,
        Certificate
    },
    directives: {
        "b-tooltip": VBTooltip,
    },
    computed: {
        ...mapGetters({
            items: "students/items",
            // topics: "students/topics",
            load: "students/load",
            // topicsLoad: "students/topicsLoad",
        }),
        id(){
            return this.$route.params.id ? this.$route.params.id : null
        },
    },
    data () {
        return {
            hooperSettings: {
                itemsToShow: 1,
                itemsToSlide:  3,
                centerMode: false,
                wheelControl: false,
                mouseDrag: false,
                shortDrag: true,
                infiniteScroll: false,
                breakpoints: {
                    500: {
                        centerMode: false,
                        itemsToShow: 1,
                        itemsToSlide: 1
                    },
                    800: {
                        itemsToShow: 2,
                        pagination: 'fraction',
                        itemsToSlide: 2
                    },
                    1000: {
                        itemsToShow: 3,
                        pagination: 'fraction'
                    },
                }
            },
            currentModule: null,
            colors: [],
        }
    },
    watch: {
        carouselData () {
            this.$refs.carousel.slideTo(this.carouselData);
        }
    },
    methods: {
        makeSlideActive(index, item) {
            this.$store.commit('students/SET_ACTIVE_MODULE', {'index': index , 'item': item } )
            this.activeSlideIndex = index;
            this.$refs.carousel.slideTo(index);
            let payload = { query:{ student_id: this.id ? this.id : this.authUser().user.id , module_id: item.id }}
            this.$store.dispatch("students/listTopics", payload )

            // console.log('---------------------this.id',this.id);
            
            const payloadCert = {query: {user_id: this.id ? this.id : this.authUser().user.id,module_id:this.items[payload.currentSlide].id}}
            this.$store.dispatch("students/certificate", payloadCert)
        },
        slidePrev(event) {
            this.$refs.carousel.slidePrev();
            const activeIndex = this.$refs.carousel.currentSlide;
            const activeItem = this.items[activeIndex];
            this.$store.commit('students/SET_ACTIVE_MODULE', {'index': activeIndex , 'item': activeItem } )
        },
        slideNext(event) {
            this.$refs.carousel.slideNext();
            const activeIndex = this.$refs.carousel.currentSlide;
            const activeItem = this.items[activeIndex];
            this.$store.commit('students/SET_ACTIVE_MODULE', {'index': activeIndex , 'item': activeItem } )
        },
        updateCarousel(payload) {
            this.currentModule = this.items[payload.currentSlide]
            const payload1 = { query:{ student_id: this.id ? this.id : this.authUser().user.id , module_id: this.items[payload.currentSlide].id }} 
            this.$store.dispatch("students/listTopics", payload1 )

            const payloadCert = {query: {user_id:this.id ? this.id : this.authUser().user.id,module_id:this.items[payload.currentSlide].id}}
            this.$store.dispatch("students/certificate", payloadCert)
        },
        removeActiveArticleClass() {
            const activeElements = document.querySelectorAll('.active-artical');
            activeElements.forEach(element => {
                element.classList.remove('active-artical');
            });
        },
        disableWheelScroll() {
            const hooperElement = this.$refs.hooperWrapper;

            hooperElement.addEventListener('wheel', (event) => {
                event.stopPropagation();
                event.preventDefault();
            }, { passive: false });
        },
        getChartOptions(percentage) {
            return {
                chart: {
                    type: 'radialBar',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    radialBar: {
                        startAngle: 0, // Adjusted to match the attachment
                        endAngle: 360, // Adjusted to match the attachment
                        hollow: {
                            margin: 0,
                            size: '60%', // Adjusted size to match the inner circle
                            background: 'transparent', // Ensuring the background matches the example
                            dropShadow: {
                                enabled: true,
                                top: 0,
                                left: 0,
                                blur: 5,
                                opacity: 0.24
                            }
                        },
                        track: {
                            background: '#fff', // Ensure the track background matches the example
                            strokeWidth: '100%',
                            margin: 0,
                            dropShadow: {
                                enabled: true,
                                blur: 4,
                                opacity: 0.2
                            }
                        },
                        dataLabels: {
                            show: true,
                            name: {
                                show: false,
                            },
                            value: {
                                formatter: function(val) {
                                    return parseInt(val) + '%';
                                },
                                color: '#333', // Adjust text color if needed
                                offsetY: 7, // Center the value label
                                offsetX: 12, // Center the value label
                                fontSize: '18px', // Adjusted font size to match the example
                                fontWeight: 'bold',
                                show: true,
                            }
                        }
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'light',
                        type: 'vertical', // Adjusted gradient direction to match the example
                        shadeIntensity: 0.5,
                        gradientToColors: ['#E84185', '#3B2C6D'], // Match the gradient color
                        inverseColors: true,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100]
                    }
                },
                stroke: {
                    lineCap: 'round'
                },
                labels: ['Percent'],
            };
        },
        getRandomColor(index) {
            if (!this.colors[index]) {
                const color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
                this.$set(this.colors, index, color);
            }
            return this.colors[index];
        },
    },
    mounted() {
        const payload = {query: {student_id: this.id ? this.id : this.authUser().user.id}}
        this.$store.dispatch("students/listModules", payload).then(_=>{
            this.currentModule = this.items[0]
            const activeSlide = this.$store.getters['students/activeModule'];
            if(activeSlide){
                this.makeSlideActive(activeSlide.index, activeSlide.item)
            }

        })
    },
  }
</script>
<style lang="scss" scoped>
.hooper{
    height: auto !important;
}
.hooper-list{
    overflow: visible !important;
}
.hooper1 .slide{
    /* background-color: black; */
    position: relative !important;

}
.hooper-slide{
    width: 300px;
}
/* .hooper1 .is-current{
    margin-top: -50px;
} */
.nav-arrows {
    background-color: white;
    /* padding: 10px; */
    border-radius: 10%;
    width: 40px;
    height: 40px;

}
.hooper1 .card-title{
    position: absolute !important;
    bottom: 8%;
    left: 13%;
    color: white;
    /* font-size: 1vw; */
}
.hooper1 .card-title h4{
    color: white;
    margin-bottom: 0 ;
}

.card-body{
  padding-bottom: 10px !important;
  background-color: #fff;
  border-radius: 15px !important;
}
.card-title{
  margin-bottom: 10px !important;
}
.custom-lesson-container-dark{
    position: relative;
    width: 100%;
    border: 2px solid #2f2f2ffc;
    border-radius: 20px;
    height: 255px;
    overflow: hidden;
}
.custom-lesson-container-dark .img-holder{
    height: 165px;
    background-color: #3A3A3A;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.custom-lesson-container-dark .content-holder{
    position: absolute;
    height: 115px;
    width: 100%;
    z-index: 999;
    padding: 15px;
    background-color: #0b0b0bfc;
    margin-top: -25px;
    border-radius: 20px;
}

/* .custom-lesson-container-dark .text-muted{
    color: #9CA3AF !important;
} */

.custom-lesson-container-dark .active-artical small{
    color: #fff !important;
}


/* .active-artical img{
    border: 2px solid #E84185 !important;
} */

 
.img-holder{
    height: 165px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.img-holder img {
    height: 100%;
    width: auto;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.content-holder{
    position: absolute;
    height: 115px;
    width: 100%;
    z-index: 999;
    padding: 15px;
    background-color: #fff;
    margin-top: -25px;
    border-radius: 20px;
}

.active-artical{
    border: 2px solid #E84185;
    color: #fff !important;
}

.active-artical .content-holder {
    border: 2px solid #E84185 !important;
    color: #fff !important;
    background-color: #E84185 !important;
}

.active-artical h4{
    color: #fff !important;
}

.active-artical small{
    color: #fff !important;
}

////////////////////////////////////////////////////////
.slide-container {
  border: 2px solid #9CA3AF;
  border-radius: 20px;
  background-color: #EEEEF2;
}

.progress {
  background-color: rgb(14 14 14);
  border-radius: 5rem;
  border: 5px solid #3B3B3B;
}

.percentage-container {
  width: 110px; 
  height: 110px;
  min-width: 110px; 
  min-height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #393939;
  border: 10px solid #393939;
  border-radius: 50%;
}

.inner-percentage {
  width: 100%;
  height: 100%;
  border: 7px solid #ff0000;
  border-radius: 50%;
  outline: 3px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.percentage {
  width: 100%;
  height: 100%;
  border: 1px solid #b6b6b6;
  border-radius: 50%;
  outline: 3px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hooper1 .is-current .slide-container {
  border: 2px solid #9CA3AF;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 4px 14px 0 rgba(34, 41, 47, 0.296) !important;
}

.dark-slide-container{
    border: 1px solid #3A3A3A;
    border-radius: 20px;
    background-color:  #101010;
}
[dir].hooper-list{
    overflow: visible !important;
}
.dark-card{
    .dark-topic-container{
        border: 1px solid #3A3A3A;
        border-radius: 20px;
    }
    .card-body{
        padding-bottom: 10px !important;
        background-color: transparent;
        border-radius: 15px !important;
    }
}
</style>